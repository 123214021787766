// Not a composable, because doesn't use Vue composables or refs
// Would some composable help with the window object interaction

export function useZendesk() {
  const zendeskShow = () => {
    if (process.client) {

      window.zE("messenger", "open");
    }
  };

  return { zendeskShow };
}
